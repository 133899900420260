@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Poppins:wght@500;600;700&display=swap');

:root {
  --neutral-10: #FFFFFF;
  --neutral-20: #F9FAFB;
  --neutral-30: #EDF0F3;
  --neutral-60: #AAAFB6;
  --neutral-80: #636B75;
  --neutral-90: #1E2024;
  --neutral-100: #0E0F11;
  --primary-10: #F0F0FF;
  --primary-20: #E6E5FF;
  --primary-60: #7D7AFF;
  --poppins: 'Poppins', sans-serif;
}
body {
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}
a {
  text-decoration: none;
}
button ,input{
  border: none;
  outline: none;
}
img {
  max-width: 100%;
  border: none;
  outline: none;
}
/* Header area start here */
.header{
  padding: 30px 0;
}
.header .right{
  gap: 24px;
}
.header .right .faq-nav{
  color: var(--neutral-90);
}
 .header .rent-btn {
  font-family: var(--poppins);
  font-weight: 500;
  height: 56px;
  padding: 0 12px;
  border: 2px solid #1E2024;
  border-radius: 12px;
  background-color: transparent;
 }
 .header .avatar {
  width: 39px;
  height: 39px;
  border-radius: 50%;
  object-fit: contain;
 }
/* Header area end here */
/* Hero area start here */
.hero-area .hero-text {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 64px;
  text-align: center;
  margin: 45px auto 0;
  margin-bottom: 50px;
}
.hero-wrapper {
  position: relative;
  margin-bottom: -100px;
  background: url("../images/hero-bg.png") no-repeat;
  background-size: 100%;
  background-position: bottom right;
  padding: 415px 30px 30px;
  z-index: 1;
}
.hero-wrapper .input-wrapper{
  display: flex;
  gap: 24px;
  padding: 16px 30px;
  background: linear-gradient(259.68deg, rgba(255, 255, 255, 0.14) 26.98%, rgba(255, 255, 255, 0) 120.72%);
  filter: drop-shadow(0px 28px 34px rgba(0, 0, 0, 0.4));
  backdrop-filter: blur(24px);
  border-radius: 24px;
  justify-content: center;
}
.hero-wrapper .input-wrapper .location{
  flex: 1;
  font-size: 18px;
  height: 72px;
  padding: 0 20px 0 50px;
  background: rgba(255, 255, 255, 0.18) url("../images/icon/location.svg") no-repeat;
  background-position: 18px center;
  backdrop-filter: blur(24px);
  border-radius: 12px;
  color: var(--neutral-30);
}
.hero-wrapper .input-wrapper .find-btn{
  font-weight: 500;
  width: 170px;
  height: 70px;
  background: var(--primary-60);
  color: var(--neutral-10);
  border-radius: 12px;
}
.vehicle-type-container .vehicle-type__control {
  width: 263px;
  height: 72px;
  border-radius: 12px;
  border: none;
  background-color: rgba(255, 255, 255, 0.18);
  box-shadow: none;
}
.vehicle-type-container .vehicle-type__control .vehicle-type__value-container {
  padding-left: 16px;
}
.time-container .time__control .time__value-container {
  background: url("../images/icon/watch.svg") no-repeat;
  background-position: 15px center;
  padding-left: 45px;
}
.time-container .time__control {
  width: 169px;
  height: 72px;
  border-radius: 12px;
  border: none;
  background-color: rgba(255, 255, 255, 0.18);
  box-shadow: none;
}
/* time__single-value  */
.time-container .time__control .time__indicator,
.vehicle-type-container .vehicle-type__control .vehicle-type__indicator {
  color: var(--neutral-10);
}
.time-container .time__control .time__placeholder,
.time-container .time__control .time__single-value,
.vehicle-type-container .vehicle-type__control .vehicle-type__placeholder,
.vehicle-type-container .vehicle-type__control .vehicle-type__single-value{
  color: var(--neutral-30);
}
/* Hero area end here */
/* why area start here */
.why-area {
  padding: 165px 0 65px;
  background-color: #F2F5FA;
}
.why-area .why-badge{
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 4px 8px;
  background: var(--primary-20);
  color: var(--primary-60);
  border-radius: 6px;
}
.why-area .title{
  font-size: 44px;
  font-weight: 600;
  margin: 15px 0;
  color: var(--neutral-100);
}
.why-area .subtitle{
  max-width: 496px;
  margin-bottom: 30px;
  color: var(--neutral-80);
}
.why-area .why-list {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.why-area .why-list .list-item{
  padding: 16px;
  background: #FFFFFF;
  box-shadow: 2px 12px 23px rgba(49, 53, 73, 0.1);
  border-radius: 12px;
}
.why-area .why-list .iconify{
  margin-right: 16px;
}
.why-area .why-list .text{
  font-size: 14px;
  font-weight: 600;
  color: var(--neutral-90);
}

/* why area end here */
/* with area start here */
.with-area {
  background-color: #F2F5FA;
  padding: 56px 0 104px 0;
}
.with-area .left{
  border-radius: 40px 0 0 40px;
  padding: 72px 0 80px 115px;
  background-color: var(--neutral-90);
}
.with-area .right{
  background: url("../images/with-bg.jpg") no-repeat;
  background-size: cover;
  border-radius: 0 40px 40px 0;
}
.with-area .left .title{
  font-size: 44px;
  color: var(--neutral-10);
}
.with-area .left .text{
  margin: 14px 0 24px;
  color: var(--primary-20);
}
.with-area .left .with-btn{
  height: 56px;
  padding: 0 16px;
  border-radius: 12px;
  color: var(--neutral-90);
  background-color: #ffffff;
}
/* with area end here */
/* FAQ area start here */
.faq-area {
  padding: 96px 0;
}
.faq-wrapper {
  padding: 40px 0 50px;
  border-radius: 40px;
  background-color: var(--primary-60);
}
.faq-wrapper .faq-top{
  text-align: center;
  max-width: 509px;
  margin: 0 auto;
}
.faq-wrapper .faq-top .title{
  font-size: 36px;
  font-weight: 600;
  margin: 18px 0;
  color: var(--neutral-10);
}
.faq-wrapper .faq-top .subtitle{
  margin-bottom: 40px;
  color: var(--neutral-20);
}
.faq-wrapper .faq-top .faq-badge{
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 6px;
  background-color: var(--primary-10);
  color: var(--primary-60);
}
.faq-item-wrapper {
  max-width: 727px;
  margin: 0 auto;
}
.faq-item-wrapper .accordion-item{
  margin-bottom: 16px;
  border-radius: 12px;
}
.faq-item-wrapper .accordion-item .accordion-button {
  padding-left: 55px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 500;
  border-radius: 12px 12px 0 0;
  color: var(--neutral-100);
}
.faq-item-wrapper .accordion-item .accordion-button:not(.collapsed) {
  background-color: #ffffff;
  box-shadow: none;
  color: var(--primary-60);
}
.faq-item-wrapper .accordion-item .accordion-button:focus {
  box-shadow: none;
}
.faq-item-wrapper .accordion-item .accordion-button.collapsed{
  border-radius: 12px;
}
.faq-item-wrapper .accordion-item .accordion-body{
  padding-left: 55px;
  padding-top: 0;
  color: var(--neutral-80);
}
.faq-item-wrapper .accordion-item .accordion-button::after {
  position: absolute;
  left: 20px;
  width: 20px;
  height: 13px;
  background-image: url("../images/icon/arrow-down.svg");
}
.faq-item-wrapper .accordion-item .accordion-button:not(.collapsed)::after {
  background-image: url("../images/icon/arrow-up.svg");
}
/* FAQ area end here */
/* CTA area start here */
.cta-area {
  margin-bottom: 72px;
}
.cta-wrapper {
  border-radius: 40px;
  background: url("../images/cta-bg.jpg") no-repeat;
  background-position: center;
  background-size: cover;
}
.cta-wrapper .cta-content{
  text-align: center;
  max-width: 504px;
  margin: 0 auto;
  padding: 64px 0;
}
.cta-wrapper .cta-content h2{
  font-size: 44px;
  font-weight: 600;
  color: var(--neutral-10);
}
.cta-wrapper .cta-content p{
  margin: 15px 0 28px;
  color: var(--neutral-60);
}
.cta-wrapper .cta-content button{
  height: 56px;
  padding: 0 16px;
  border-radius: 12px;
  background-color: var(--primary-60);
  color: var(--neutral-10);
}
/* CTA area end here */
/* footer area start here */
.footer-top {
  padding: 35px 0;
  flex-flow: row wrap;
}
.footer-top .footer-links {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  gap: 32px;
}
.footer-top .social-icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
}
.footer-bottom {
  padding: 20px 0;
  flex-flow: row wrap;
}
.footer-bottom .pages-links{
  gap: 16px;
}
.footer-top a,
.footer-bottom a {
  color: var(--neutral-80);
}
.copyright-text {
  color: var(--neutral-80);
}
/* footer area end here */
.cars-top {
  margin: 25px;
  flex-flow: row wrap;
  gap: 20px;
}
.cars-top .result {
  font-size: 14px;
  font-weight: 700;
  font-family: var(--poppins);
  color: #000000;
}
.cars-top .layout  {
  gap: 8px;
}
.cars-top .layout .single-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;
}
.cars-top .layout .single-layout.active {
  background: #FFFFFF;
  box-shadow: 0px 8px 24px rgba(47, 52, 70, 0.23);
}
.cars-top .layout .single-layout.active img {
  filter: brightness(0) saturate(100%) invert(26%) sepia(81%) saturate(4008%) hue-rotate(227deg) brightness(96%) contrast(92%);
}
.single-car {
  display: block;
  padding: 6px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(24px);
  filter: drop-shadow(-8px 14px 24px rgba(47, 52, 70, 0.13));
  border-radius: 24px;
  margin-bottom: 30px;
}
.single-car .car-img{
  width: 100%;
  border-radius: 22px;
  margin-bottom: 30px;
}
.single-car .car-name{
  font-family: var(--poppins);
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 4px;
  color: #000000;
}
.single-car .car-content{
  padding: 0 20px;
  margin-bottom: 10px;
}
.single-car .car-content .model{
  margin-bottom: 8px;
  font-family: var(--poppins);
  font-size: 12px;
  color: #838D95;
}
.single-car .car-content .car-info .ratting{
  gap: 4px;
  padding: 0 6px;
  height: 28px;
  border-radius: 8px;
  background-color: #FFC53D;
}
.single-car .car-content .car-info .price{
  line-height: 18px;
  font-family: var(--poppins);
  font-size: 18px;
  font-weight: 700;
  color: var(--primary-60);
}
.single-car .car-content .car-info .price-wrapper{
  gap: 8px;
}
.single-car .car-content .car-info .price-wrapper .price-text{
  font-family: var(--poppins);
  font-size: 10px;
  font-weight: 600;
  color: var(--neutral-80);
}
.single-car .car-content .car-info .ratting .count{
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}
.instant-booking-wrapper {
  margin-bottom: 20px;
  flex-flow: row wrap;
  gap: 15px;
}
.instant-booking-wrapper .instant-booking {
  gap: 32px;
  flex-flow: row wrap;
}
.instant-booking-wrapper .instant-booking .booking-label {
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-60);
}
.instant-booking-wrapper .show-map-label {
  font-size: 14px;
  font-weight: 600;
  color: #0E0F11;
}
.form-switch .form-check-input.booking-check {
  width: 32px;
  height: 20px;
  border-color: #52C41A;
  box-shadow: none;
}
.form-switch .form-check-input.show-map-check {
  width: 32px;
  height: 20px;
  border-color: #2F54EB;
  box-shadow: none;
}
.form-switch .form-check-input.show-map-check:checked {
  background-color: #2F54EB;
}
.form-switch .form-check-input.booking-check:checked {
  background-color: #52C41A;
}
.result-wrapper {
  margin-bottom: 90px;
}
.find-car-input-wrapper  {
  gap: 24px;
  margin: 30px 0;
  flex-flow: row wrap;
}
.find-car-input-wrapper .location {
  width: 342px;
  height: 64px;
  border-radius: 12px;
  padding-left: 50px;
  padding-right: 20px;
  backdrop-filter: blur(24px);
  color: var(--neutral-90);
  background: #F2F5FA url("../images/icon/location-color.svg") no-repeat;
  background-position: 18px center;
}
.find-car-input-wrapper .seats {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 189px;
  height: 64px;
  border-radius: 12px;
  background: #F2F5FA;
  backdrop-filter: blur(24px);
}
.find-car-input-wrapper .seats .text{
  font-size: 18px;
  color: var(--neutral-90);
}
.find-car-input-wrapper .seats .count{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  width: 38px;
  height: 30px;
  background: #FFFFFF;
  border-radius: 8px;
  color: var(--primary-60);
}
.select-type-container .select-type__control  {
  width: 263px;
  height: 64px;
  border-radius: 12px;
  border: none;
  box-shadow: none;
  padding-left: 10px;
  background: #F2F5FA;
  backdrop-filter: blur(24px);
}
.time-now-wrapper .time-now__control{
  width: 169px;
  height: 64px;
  border-radius: 12px;
  border: none;
  box-shadow: none;
  padding-left: 35px;
  backdrop-filter: blur(24px);
  background: #F2F5FA url("../images/icon/watch-color.svg") no-repeat;
  background-position: 16px center;
}
/* time__single-value  */
.time-now-wrapper .time-now__control .time-now__indicator,
.select-type-container .select-type__control .select-type__indicator {
  color: var(--neutral-90);
}
.sort-container .sort__control  {
  width: 170px;
  border: none;
  box-shadow: none;
}
.sort-container .sort__control .sort__indicator {
  color: var(--primary-60);
}
.sort-container .sort__control .sort__placeholder,
.sort-container .sort__control .sort__single-value {
  font-family: var(--poppins);
  font-size: 14px;
  font-weight: 600;
  color: var(--neutral-80);
}
.time-now-wrapper .time-now__control .time-now__placeholder,
.time-now-wrapper .time-now__control .time-now__single-value,
.select-type-container .select-type__control .select-type__placeholder,
.select-type-container .select-type__control .select-type__single-value{
  color: var(--neutral-90);
}
.radius-container .radius__control {
  width: 110px;
  border: none;
  box-shadow: none;
}
.kraftstoff-container .kraftstoff__control {
  width: 140px;
  border: none;
  box-shadow: none;
}
.radius-container .radius__control .radius__placeholder,
.radius-container .radius__control .radius__single-value{
  font-weight: 600;
  color: var(--neutral-80);
}
.kraftstoff-container .kraftstoff__control .kraftstoff__placeholder,
.kraftstoff-container .kraftstoff__control .kraftstoff__single-value{
  font-weight: 600;
  color: var(--neutral-80);
}
.booking-card {
  padding: 24px 21px;
  background: #FFFFFF;
  box-shadow: -8px 14px 24px rgba(47, 52, 70, 0.1);
  border-radius: 32px;
}
.booking-card .price{
  font-size: 44px;
  font-weight: 600;
  color: var(--neutral-100);
}
.booking-card .price-text{
  font-size: 14px;
  font-weight: 600;
  color: #636B75;
}
.booking-card .card-btn{
  font-size: 14px;
  font-weight: 500;
  font-family: var(--poppins);
  height: 60px;
  width: 100%;
  border-radius: 12px;
  background-color: #1E2024;
  color: #ffffff;
}
.booking-card .car-owner {
  margin-bottom: 16px;
}
.booking-card .car-owner .name{
  gap: 12px;
}
.booking-card .car-owner .name img{
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.booking-card .car-owner .name h4{
  font-size: 14px;
  font-weight: 600;
  color: #0E0F11;
}
.booking-card .car-owner .ratting .star{
  gap: 6px;
}
.booking-card .car-owner .ratting .text{
  font-size: 11px;
  font-family: var(--poppins);
  color: #838D95;
}

.key-box {
  gap: 26px;
  background: #F0F0FF;
  border-radius: 16px;
  padding: 20px;
  margin-bottom: 45px;
}
.key-box h4{
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 3px;
  color: #1E2024;
}
.key-box p{
  font-size: 14px;
  max-width: 442px;
  color: #1E2024;
}
.details-info-area .title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #000000;
}
.tecnical-list  {
 margin-bottom: 33px;
}
.tecnical-list .list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  color: #838D95;
}
.tecnical-list .list-item .right{
  font-size: 18px;
  font-weight: 600;
}
.details-info-area {
  margin-bottom: 100px;
}
.details-info-area .head{
  font-size: 44px;
  font-weight: 600;
  color: #000000;
}
.details-info-area .head-mini{
  font-size: 18px;
  font-weight: 600;
  color: #838D95;
}
.details-info-area .kilometers{
  margin-bottom: 32px;
}
.badge-text {
  font-size: 18px;
  margin-right: 12px;
  color: #636B75;
}
.badge-count {
  font-size: 14px;
  font-weight: 600;
  padding: 0 6px;
  font-family: var(--poppins);
  background: #7D7AFF;
  border-radius: 4px;
  color: #FFFFFF;
}
.badge-wrapper {
  margin: 13px 0 25px;
}
.location-box .title {
  margin-bottom: 9px;
}
.location-box .subtitle {
  display: inline-block;
  font-size: 14px;
  margin-bottom: 24px;
  color: #838D95;
}
.insurance-box {
  margin-bottom: 30px;
}
.slick-slider.center {
  overflow: hidden;
  margin: 46px 0 54px;
}
.slick-slider.center .slick-slide {
  padding: 0 15px;
}
.slick-slider.center .slick-slide .single-slide{
  position: relative;
  border-radius: 40px;
  overflow: hidden;
}
.slick-slider.center .slick-slide .single-slide .car-img{
  width: 100%;
  height: 416px;
  object-fit: cover;
}
.slick-slider.center .slick-slide .single-slide .location{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  height: 32px;
  left: 33px;
  top: 20px;
  padding: 0 8px;
  background: #7D7AFF;
  box-shadow: -2px 12px 24px rgba(0, 0, 0, 0.14);
  border-radius: 10.1361px;
}
.slick-slider.center .slick-slide .single-slide .location span{
  font-family: var(--poppins);
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}
.slick-slider.center .slick-slide .single-slide .location img{
  width: 12px;
  height: 16px;
}
.slick-slider.center .slick-slide .single-slide .ratting{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  right: 27px;
  bottom: 24px;
  height: 30px;
  padding: 0 7px;
  background: #FFC53D;
  border-radius: 7.57143px;
}
.slick-slider.center .slick-slide .single-slide .ratting span{
  font-size: 14px;
  font-weight: 700;
  font-family: var(--poppins);
  color: #ffffff;
}
.slick-slider.center .slick-slide .single-slide::after{
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  transition: all .3s linear;
}
.slick-slider.center .slick-slide.slick-current .single-slide::after{
  background-color: transparent;
}