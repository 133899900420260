@media (max-width: 1399px) {
  .with-area .left {
    padding: 72px 0 80px 100px;
  }
}

@media (max-width: 1199px) {
  .faq-item-wrapper {
    padding: 0 20px;
  }

  .with-area .left {
    padding: 72px 0 80px 30px;
  }

  .hero-wrapper {
    background: url("../images/hero-bg-2.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    padding: 50px 30px;
    margin-top: 30px;
    border-radius: 32px;
  }

  .hero-wrapper .input-wrapper {
    flex-flow: row wrap;
  }

  .hero-area .hero-text {
    margin: 15px auto 0;
    font-size: 36px;
  }

  .vehicle-type-container,
  .hero-wrapper .input-wrapper .find-btn {
    width: calc(50% - 12px);
  }

  .vehicle-type-container .vehicle-type__control {
    width: 100%;
  }

}

@media (max-width: 991px) {
  .footer-top .social-icons {
    margin-top: 30px;
    justify-content: center;
  }

  .with-area .left .title {
    font-size: 30px;
  }

  .why-area .why-img {
    margin-top: 40px;
  }

  .why-area .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

@media (max-width: 850px) {
  .slick-slider.center {
    margin: 20px 0 34px;
  }
}

@media (max-width: 767px) {
  .footer-top .footer-links {
    flex-direction: column;
    align-items: center;
  }

  .booking-card {
    margin-top: 30px;
  }

  .details-info-area {
    margin-bottom: 40px;
  }

  .result-wrapper {
    margin-bottom: 30px;
  }

  .cta-wrapper .cta-content h2 {
    font-size: 30px;
  }

  .cta-area {
    margin-bottom: 32px;
  }

  .with-area .left {
    border-radius: 40px;
    text-align: center;
    padding: 50px 20px;
  }

  .hero-area .hero-text {
    font-size: 36px;
  }

  .hero-wrapper .input-wrapper .location,
  .time-container,
  .time-container .time__control,
  .vehicle-type-container,
  .vehicle-type-container .vehicle-type__control,
  .hero-wrapper .input-wrapper .find-btn {
    width: 100%;
  }
}

@media (max-width: 575px) {
  .header {
    padding: 30px 10px;
  }

  .footer-bottom {
    flex-direction: column;
    align-items: center;
  }

  .find-car-input-wrapper .location,
  .time-now-wrapper,
  .select-type-container,
  .select-type-container .select-type__control,
  .time-now-wrapper .time-now__control {
    width: 100%;
  }

  .cta-wrapper .cta-content {
    padding: 50px 20px;
  }

  .faq-wrapper .faq-top {
    padding: 0 10px;
  }

  .faq-wrapper .faq-top .title {
    font-size: 30px;
  }
}

@media (max-width: 500px) {
  .header>div {
    flex-direction: column;
  }

  .header>div .right {
    margin-top: 10px;
  }

  .instant-booking-wrapper .instant-booking {
    gap: 10px;
  }
}